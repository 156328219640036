// Home page hero banner

.home-hero {
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  color: $white;
  @media(max-width:575px) {
    background-image: linear-gradient(to bottom, #672374, #332f84) !important;
  }
  
  .container {
    
    padding: 15px 0;
    @media (min-width: 420px) {
      padding: 30px 0;
    }
    @media (min-width: 576px) {
      padding: 45px 0;
    }
    @media (min-width: 768px) {
      padding: 60px 0;
    }
    @media (min-width: 992px) {
      //padding: 75px 0;
    }
    @media (min-width: 1200px) {
      //padding: 90px 0;
    }
    
    .no-gutters {
      padding: 2rem;
      @media (min-width: 576px) {
        padding: 0;
      }
    }
    
    .hero-text {
      .btn-success {
        padding: 0.60rem 5rem;
        font-size: 1rem;
        border-radius: 0.3rem;
        background-color: $metro-primary !important;
        @media(min-width: 1200px) {
          padding: 0.60rem 8rem;
        }
  
        &:hover,&:focus,&:active {
          background-color: $dark-green !important;
          box-shadow: none !important;
          border-color: $dark-green !important;
        }
      }
    }
    
    h1 {
      font-weight: 600;
      margin: 1rem 0;
      font-size: 1.5rem;
      @media(min-width: 768px) {
        font-size: 1.75rem;
        margin: 1rem 0;
      }
      @media(min-width: 992px) {
        font-size: 2.5rem;
      }
      
      a {
        color: $white;
        font-weight: 700;
        font-size: 2.25rem;
        @media(min-width: 768px) {
          font-size: 5rem;
        }
      }
    }
    
    h2 {;
      margin: 1rem 0;
      
      a {
        color: #fff;
        font-weight: 300;
        font-size: 3rem;
        text-decoration: none;
        
        &:hover, &:focus {
          color: #fff;
          text-shadow: 0 2px 2px #1c2739;
          transition: ease-in .3s;
        }
        
        @media(min-width: 992px) {
          font-size: 4rem;
        }
        @media(min-width: 1200px) {
          font-size: 4.5rem;
        }
      }
    }
    
    h3 {
      font-size: 1.75rem;
      margin: 0;
      font-weight: 400;
      @media(min-width: 768px) {
        font-size: 2.625rem;
      }
    }
    
    p {
      margin: 0.9375rem 0 0 0;
    }
    
    small {
      font-size: 1rem;
      font-weight: 400;
    }
    
  }
  
}


#homeCarousel {
  
  .carousel-item {
    min-height: 250px;
    background-color: $tertiary;
  }
  
  .container {
    position: relative;
  }
  
  .hero-badge, .hero-human {
    position: absolute;
  }
  
  .hero-human {
    bottom: 0;
    left: 0;
    @media (min-width: 1200px) {
      left: 100px;
    }
    
    img {
      height: 350px;
      //height: 90vh;
      width: auto;
      @media (min-width: 1200px) {
        height: 30vw;
        max-height: 500px;
      }
    }
  }
  
  .hero-badge {
    
    img {
      height: 130px;
      width: auto;
      position: relative;
      z-index: 20;
      top: -25px;
      right: 45px;
    }
  }
  
  .cta-box {
    position: absolute;
    top: -380px;
    right: 15px;
    padding: 1.875rem 1.875rem 1.875rem 3.75rem;
    width: auto;
    min-width: 550px;
    @media (min-width: 1200px) {
      right: 100px;
      top: -420px;
    }
    
    h5 {
      margin-bottom: 1.1875rem;
    }
    
    h1 {
      font-size: 2.25rem;
      font-weight: 700;
      white-space: nowrap;
    }
    
    h5 {
      .text-yellow {
        font-size: 3.125rem;
        font-weight: 700;
      }
    }
    
    .btn-book {
      padding: 0.9375rem 7.5rem;
      
      .text-yellow {
        font-size: 1.75rem;
        font-weight: 700
      }
    }
  }
  
}


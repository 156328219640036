.message-wrapper {
	position:         absolute;
	bottom:           6rem;
	background-color: #494949;
	color:            #FFF;
	font-weight:      600;
	border-radius:    1rem;
	width:            50%;
	@media(min-width: 430px) {
		bottom: 10rem;
	}
	@media(min-width: 576px) {
		bottom: 12rem;
		width:  60%;
	}
	@media(min-width: 768px) {
		width: 70%;
	}
	@media(min-width: 992px) {
		bottom: 9.5rem;
		right:  136px;
		width:  31%;
	}
	@media(max-width: 991px) {
		right: 12rem;
	}
	@media(max-width: 575px) {
		right: 10rem;
	}
	
	.message-box {
		position: relative;
		padding:  1rem;
		@media(min-width: 992px) {
			padding:    1.5rem 3rem 1.5rem 1rem;
			text-align: left;
		}
		
		.message-text {
			&:after {
				position:    absolute;
				content:     "";
				bottom:      -40px; //matching border-top of this
				right:       5rem;
				border-top:  40px solid #494949;
				border-left: 35px solid transparent;
			}
			
			p {
				margin: 0;
			}
		}
	}
}

.message-girl {
	float: right;
	@media(min-width: 992px) {
		position: absolute;
		bottom:   0;
		right:    -1rem;
	}
	@media(min-width: 1200px) {
		right: 0;
	}
	@media(max-width: 575px) {
		margin-right: -2rem;
	}
}

.footer-payicons {
	max-height: 25px;
	height:     auto;
	width:      auto;
}

.footer-find-near {
	.fa-map-marker-alt {
		width:  30px;
		height: 30px;
	}
	
	a {
		text-decoration: none;
	}
	
	.footer-find-near-main {
		color:       $metro-learn-more;
		line-height: 1.4rem;
		
		img {
			margin-left:   auto;
			margin-right:  auto;
			margin-bottom: 1rem;
			@media(min-width: 768px) {
				float: left;
			}
		}
		
	}
	
	span {
		color:       $white;
		font-weight: 600;
		display:     inline-block;
		@media(min-width: 768px) {
			display: block;
		}
	}
}

.social-icons {
	a:hover {
		text-decoration: none;
	}
}

.license-number-footer {
	color:      $metro-text-dark;
	text-align: center;
	
	@media (min-width: 992px) {
		text-align: left;
	}
	
	strong {
		font-size:   .75rem;
		color:       $metro-subtitle;
		line-height: 1.8;
	}
	
	ul {
		list-style: none;
		padding:    0;
		margin:     0;
	}
	
	li {
		display: block;
		@media (min-width: 576px) {
			display: inline-block;
		}
	}
	
	span {
		display: none;
		padding: 0 .5rem;
		
		@media (min-width: 576px) {
			display: inline-block;
		}
	}
	
}

.license-footer .lic-no {
	display:     inline-block;
	color:       $metro-text-dark;
	font-weight: 400;
}
.pagination {
  display: inline-flex;
  margin-top: 3rem;
  
  .page-item {
    .page-link {
      border-radius: 50px;
      margin: 5px;
      height: 37px;
      width: 38px;
    }
  }
}

.btn.loadMoreBtn {
  text-align: center;
  background-color: #01c906;
  margin-top: 2rem;
  color: $white !important;
  font-size: 1rem;
  font-weight: 700;
}

.btn.loadMoreBtn:hover {
  text-decoration: none;
}
.blank-section {
	min-height: 6rem;
	@media(min-width: 576px) {
		min-height: 7.5rem;
	}
	@media(min-width: 768px) {
		min-height: 6rem;
	}
	@media(min-width: 992px) {
		min-height: 8rem;
	}
}

.custom-banner {
	/*temporarily disabled for smart doorbell banner*/
	/*background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;

	.col-12 {
		margin-top: -6rem;
		margin-bottom: -3rem;
	}*/
	
	h4 {
		font-size:   1.75rem;
		font-weight: 700;
		color:       $metro-title;
		@media(min-width: 576px) {
			font-size: 1.75rem;
		}
		@media(min-width: 992px) {
			font-size: 2.25rem;
		}
	}
	
	p {
		font-size: 1rem;
		color:     $metro-text-dark;
		padding:   1rem 0;
	}
	
	.custom-bookcall {
		.btn {
			border-radius: 0.3rem;
			padding:       0.75rem 3rem;
			font-size:     1rem;
		}
	}
	
	.first-col {
		text-align:       center;
		background-image: linear-gradient(#202F4C, #0A101C);
		@media(min-width: 992px) {
			position: relative;
		}
	}
	
	.second-col {
		background-color: #FFF;
		box-shadow:       0 1px 3px 0 #363636;
		padding:          3rem;
		text-align:       center;
		@media(min-width: 768px) {
			text-align: left;
		}
		@media(min-width: 992px) {
			box-shadow: 1px 0 3px 0 #363636;
			padding:    10rem 3rem;
		}
		@media(min-width: 1200px) {
			padding: 13rem 7rem;
		}
		@media(max-width: 768px) {
			margin-top: -6rem !important;
		}
		
		h2 {
			font-weight: 700;
		}
		
		p {
			color: $metro-text-dark;
		}
	}
	
	.banner-human {
		width:  50%;
		height: auto;
		@media(min-width: 768px) {
			position: absolute;
			width:    100%;
			bottom:   0;
			right:    -2rem;
			z-index:  1;
		}
		@media(max-width: 992px) {
			margin-top: -3rem;
		}
	}
	
	.btn-primary {
		font-size: 1rem;
	}
}

.call-section {
	.container-left-half {
		//these padding and margin are to reflect original bootstrap container
		//padding-right: 15px;
		//padding-left: 15px;
		margin-right: auto;
		margin-left:  auto;
		width:        100%;
		@media (min-width: 576px) {
			//max-width for container at this width is 540px
			max-width: calc(540px - 30px);
		}
		@media (min-width: 768px) {
			margin-right: 0; //so the box aligns left;
			//max-width for container at this width is 720px
			max-width:    calc(360px - 30px);
		}
		@media (min-width: 992px) {
			//max-width for container at this width is 960px
			max-width: calc(480px - 30px);
		}
		@media (min-width: 1200px) {
			//max-width for container at this width is 1140px
			max-width: calc(570px - 30px);
		}
	}
	
	.bg-gradiant {
		background:     linear-gradient(90deg, rgba(99, 5, 91, 1) 0%, rgba(131, 2, 102, 1) 35%, rgba(166, 5, 108, 1) 100%);
		padding-top:    5rem;
		padding-bottom: 5rem;
		
		h3 {
			color:       $white;
			font-size:   2rem;
			font-weight: 700;
		}
		
		p {
			color:          $white;
			padding-top:    2rem;
			padding-bottom: 2rem;
		}
	}
	
	.bg-image {
		background-position: top center;
		background-size:     cover;
		background-repeat:   no-repeat;
		min-height:          250px;
	}
	
}


/*Temporary Smart Door Bell Banner*/

.custom-banner {
	background-size:     cover;
	background-position: center right;
}


@media(min-width: 992px) {
	.custom-banner {
		background-size:     cover;
		background-position: center;
		margin-bottom:       6rem;
	}
}

.custom-banner .dool-bell-left {
	img {
		height:    100%;
		width:     auto;
		max-width: none;
		@media(min-width: 992px) {
			margin-left: -10rem;
		}
	}
	
}

.custom-banner .dool-bell-right {
	padding-top:    3rem;
	padding-bottom: 3rem;
	@media(min-width: 568px) {
		padding-top:    4rem;
		padding-bottom: 4rem;
	}
}

.custom-banner h3 {
	color:       #FFF;
	font-size:   3rem;
	font-weight: bold;
	@media(min-width: 768px) {
		font-size: 5rem;
	}
}

.custom-banner h5 {
	color:       #58E1F1;
	font-size:   1.5rem;
	font-weight: bold;
	@media(min-width: 768px) {
		font-size: 1.6rem;
	}
}

@media (min-width: 992px) {
	.custom-banner ul {
		columns:         2;
		-webkit-columns: 2;
		-moz-columns:    2;
		padding:         1rem 0;
	}
}

.custom-banner li {
	color:          #FFF;
	padding:        0.3rem 0;
	font-size:      1rem;
	font-weight:    bold;
	font-style:     italic;
	letter-spacing: 0.05rem;
	@media(min-width: 768px) {
		font-size: 1.1rem;
	}
}

.custom-banner ul li::before {
	content:      '>';
	margin-right: 0.5rem;
	color:        #58E1F1;
	font-size:    1.3rem;
	font-weight:  bold;
}

.custom-banner .btn-primary {
	background-color: #29A8D3;
	border:           2px solid #29A8D3;
	padding:          15px 30px;
}

.custom-banner .btn-primary:hover {
	background-color: #35ADCA !important;
	border:           2px solid #35ADCA !important;
	padding:          15px 30px;
}


.festival-section-mobile {
	width:     100%;
	max-width: none;
}

.festival-section {
  margin-bottom: 4rem;
  @media (min-width: 768px) {
      margin-bottom: 7rem;
  }
  
  .bg-image {
    background-position: center center;
    background-size:     cover;
    background-repeat:   no-repeat;
  }
  
  .left-section {
    background-color: #C5BBB4;
    padding:          5rem 1rem 5rem;
  
    h3 {
      color:       #FFF;
      font-weight: 600;
      @media (min-width: 768px) {
        font-size:   2.25rem;
        font-weight: 700;
      }
    }
  
    p {
      color:   #FFF;
      padding: 1rem 0;
      @media (min-width: 768px) {
        padding: 1rem 2rem 1rem 0;
      }
    }
  
    .btn {
      padding:   .75rem 2.5rem;
      font-size: 1rem;
    }
  }
}

.newyear .left-section {
	background-color: #C07941;
}
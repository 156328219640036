//top nav SCSS file

.top-gradient {
  height: 5px;
  //background-color: $tertiary;
  //background-image: linear-gradient(to right, $secondary, $tertiary);
  background-color: #5fc9bd;
  background-image: linear-gradient(90deg, #ffa300 0%, #ffa300 35%, #ff8702 100%);
}

.header-service {
  //font-weight: 400;
  letter-spacing: 0.03rem;
  .active {
    background-color: $metro-primary;
  }
}

.top-nav {
  background-color: $metro-title;
  color: $white;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.3125rem 0;
  line-height: 2.7;
  @media (min-width: 990px) {
    padding: 0
  }
  
  @media (min-width: 420px) {
    font-size: 0.625rem;
  }
  @media (min-width: 1200px) {
    font-size: 0.75rem;
  }
  
  .metro-front {
    font-weight: bold;
  }
  
  a {
    &:hover {
      color: #ccc;
    }
  }
  
  ul {
    margin: 0;
    padding: 0;
    /* font-weight: 600;*/
    
    li {
      display: inline-block;
      vertical-align: middle;
      text-align: right;
      /*line-height: 1.4;*/
      
      &:after {
        content: "";
        height: 1rem;
        display: inline-block;
        position: absolute;
        top: 13px;
        right: auto;
        border-right: 2px solid $metro-text-dark;
      }
      
      &:hover {
        //background-color: #21293d
        text-decoration: underline;
      }
      
      a {
        text-decoration: none;
        outline: none;
        color: #b7c2c7;
        text-transform: capitalize;
        padding: 0.3125rem 0.625rem;
        letter-spacing: 0.03rem;
        
        &:hover {
          text-decoration: none;
          color: #eee;
        }
        
        @media(min-width: 768px) {
          padding: 0.33rem 0.625rem;
          &:after {
            /*content: '|';*/
            margin-left: 20px;
            font-weight: 300;
            color: #7a828f;
          }
        }
      }
      
      &:last-child {
        &:after {
          border-right: none;
        }
        
        a {
          /*padding-right: 0;*/
          &:after {
            content: '';
            margin-left: 0;
          }
        }
      }
      
    }
  }
  
  .active {
    //background-color: $metro-top-header-color;
    
    &:after {
      //border-right: none;
    }
    
    a {
      color: white
    }
    
  }
  
  .click-disable {
    pointer-events: none;
  }
  
  .nav-bookcall {
    line-height: 1;
    
    .hours {
      height: 40px;
      width: auto;
      vertical-align: middle;
      @media (min-width: 576px) {
        height: 40px;
      }
      
    }
    
    .nav-phone {
      font-size: 1rem;
      padding-left: 0.3rem;
      font-weight: 600;
      vertical-align: middle;
      @media (min-width: 375px) {
        font-size: 1.5rem;
        padding-left: 0.25rem;
        font-weight: 700;
      }
      @media (min-width: 576px) {
        font-size: 1.8rem;
        padding-left: 0.8rem;
      }
    }
    
    .btn-small {
      @media (max-width: 576px) {
        position: absolute;
        top: -5px;
        right: 0;
        height: 50px;
        border-radius: 0;
        width: 150px;
        padding-top: 0.75rem;
      }
    }
  }
  
  .hours {
    max-width: 30px;
  }
  
  .nav-phone {
    color: white;
    font-weight: bold;
    font-size: 1rem;
    text-decoration: none;
    vertical-align: middle;
  }

  #metropolitan-menu {
    li:after {
      border: 0 !important;
    }
  }
}

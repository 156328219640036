.introduction-section {
  background-color: $metro-intro-bg;
  padding: 3rem 0 0 0;
  @media(min-width: 992px) {
    padding: 4rem 0 3rem 0;
  }
  
  .left-side-intro {
    .img-border {
      border: 0.4rem solid #fff;
      border-radius: 0.4rem;
      .left-side-img{
        border-radius: 0.4rem;
      }
    }
    
  }
  
  .right-side-intro {
    padding-left: 1rem;
    padding-bottom: 1rem;
    @media(min-width: 992px) {
      padding-left: 2rem;
      padding-bottom: 0;
    }
    
    h2 {
      color: $metro-title;
      
      font-size: 2.2rem;
      font-weight: bold;
      padding-top: 2rem;
      @media(min-width: 768px) {
        padding-top: 0;
        font-size: 2.1rem;
      }
      @media(min-width: 992px) {
        font-size: 2.5rem;
        padding-top: 0;
      }
      
      span {
        color: $metro-primary;
      }
    }
    
    p {
      font-weight: 600;
      padding-top: 1rem;
      
    }
    
    img{
      margin-top: 1rem;
    }
 
     
      
      ul {
        color: #fff;
        padding-left: 0;
        list-style: none;
        @media(min-width: 992px) {
          padding-top: 1rem;
        }
        
        li {
          color: $metro-title;
          font-weight: 600;
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
         
            background-image: url("#{$img-path}/shield-bullet.png");
            display: block;
            background-repeat: no-repeat;
            background-position: center left;
            vertical-align: middle;
            padding-left: 2rem;
          
          @media(min-width: 768px) {
            font-size: 1rem;
          }
          @media(min-width: 996px) {
            font-size: 1.2rem;
          }
         }
      }
      
    
  }
}

//single brand page css

.single-brands-intro{
  .introduction-section{
    @media (min-width: 768px) {
      padding: 3rem 0 2rem 0;
    }
    .right-side-intro{
      @media (min-width: 992px) {
        min-height: 16rem;
      }
    }
  }
}

.employee-main {
  //position: relative;
  .employee-img {
    position: absolute;
    z-index: 1;
    //width: 50%;
    right: -2%;
    bottom: -51px;
  }
}

.semi-circle-wrapper-intro {
  height: 100px;
  width: 100%;
  overflow: hidden;
  background-color: white;
  position: relative;
  @media(min-width: 992px) {
    margin-top: -3rem;
    margin-bottom: 3rem;
  }
  
  .semi-circle-intro {
    position: absolute;
    background-color: $metro-intro-bg;
    height: 500vw;
    width: 500vw;
    border-radius: 50%;
    bottom: 0;
    left: -200vw;
    @media(min-width: 2000px) {
      height: 1000vw;
      width: 1000vw;
      left: -450vw;
    }
  }
  
}

//bottom Space

.bottom-space{
  margin-bottom: 5rem;
  @media (min-width: 992px) {
    margin-bottom: 8rem;
  }
}
// contact page css

.page-contact {
  padding-top: 2rem;
  
  @media (min-width: 768px) {
    padding-bottom: 2rem;
  }
  
  .contact-left {
    @media (min-width: 1200px) {
      padding-right: 4rem;
    }
  }
  
  .contact-right {
    
    padding-top: 2rem;
    padding-left: 2rem;
    padding-bottom: 4rem;
    @media (min-width: 768px) {
      padding-top: 0;
      padding-left: 0;
    }
    
  }
  
  @media (min-width: 768px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  
  // left contact page css
  .contact-left {
    .mauticform_wrapper {
      padding: 2.4rem;
    }
  }
  
  .cont-call {
    padding-top: 2rem;
    font-size: 2rem;
    color: #868686;
    @media (min-width: 992px) {
      font-size: 3.5rem;
    }
    
    a {
      color: #3f0d42;
      font-weight: 700;
    }
  }
  
  p {
    color: #858585;
    line-height: 2rem;
  }
  
  .expert {
    font-size: 1rem;
    color: $black;
    padding-bottom: 1rem;
    
    span {
      color: $metro-primary;
      font-weight: bold;
    }
  }
  
  .icon {
    display: flex;
    
    a {
      margin-right: 1rem;
    }
    
    i {
      color: #5fc9bd;
    }
  }
  
  .contact-vanMobile {
    margin: 0 auto -40px auto;
    z-index: 2;
    max-width: 70%;
    @media (min-width: 992px) {
      max-width: 100%;
    }
    
  }
  
  .contact-social {
    
    @media (min-width: 768px) {
      padding-bottom: 3rem;
    }
    
    .fab {
      font-size: 2rem;
      padding-right: 10px;
      color: #5dcdc1;
    }
    
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      
      li {
        display: inline-block;
      }
    }
  }
}

.page-contact-selling {
  .home-points {
    margin-bottom: -6rem;
    @media (min-width: 968px) {
      margin-bottom: -2rem;
    }
  }
  
  .page-mode-selling {
    padding-bottom: 0;
  }
}

.page-contact-award {
  padding-top: 0;
}





/*
* Enable and replace font names/paths as required
 */

@import "theme/theme_variables";

/*@font-face {
  font-family: 'Poppins';
  src: url('#{$font-path}/poppins/Poppins-ExtraLight.eot');
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
  url('#{$font-path}/poppins/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/poppins/Poppins-ExtraLight.woff2') format('woff2'),
  url('#{$font-path}/poppins/Poppins-ExtraLight.woff') format('woff'),
  url('#{$font-path}/poppins/Poppins-ExtraLight.ttf') format('truetype'),
  url('#{$font-path}/poppins/Poppins-ExtraLight.svg#Poppins-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}*/
@font-face {
  font-family: 'Poppins';
  src: url('#{$font-path}/poppins/Poppins-Light.eot');
  src: local('Poppins Light'), local('Poppins-Light'),
  url('#{$font-path}/poppins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/poppins/Poppins-Light.woff2') format('woff2'),
  url('#{$font-path}/poppins/Poppins-Light.woff') format('woff'),
  url('#{$font-path}/poppins/Poppins-Light.ttf') format('truetype'),
  url('#{$font-path}/poppins/Poppins-Light.svg#Poppins-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: 'Poppins';
  src: url('#{$font-path}/poppins/Poppins-Regular.eot');
  src: local('Poppins Regular'), local('Poppins-Regular'),
  url('#{$font-path}/poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/poppins/Poppins-Regular.woff2') format('woff2'),
  url('#{$font-path}/poppins/Poppins-Regular.woff') format('woff'),
  url('#{$font-path}/poppins/Poppins-Regular.ttf') format('truetype'),
  url('#{$font-path}/poppins/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}
/*@font-face {
  font-family: 'Poppins';
  src: url('#{$font-path}/poppins/Poppins-Medium.eot');
  src: local('Poppins Medium'), local('Poppins-Medium'),
  url('#{$font-path}/poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/poppins/Poppins-Medium.woff2') format('woff2'),
  url('#{$font-path}/poppins/Poppins-Medium.woff') format('woff'),
  url('#{$font-path}/poppins/Poppins-Medium.ttf') format('truetype'),
  url('#{$font-path}/poppins/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}*/
@font-face {
  font-family: 'Poppins';
  src: url('#{$font-path}/poppins/Poppins-SemiBold.eot');
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
  url('#{$font-path}/poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/poppins/Poppins-SemiBold.woff2') format('woff2'),
  url('#{$font-path}/poppins/Poppins-SemiBold.woff') format('woff'),
  url('#{$font-path}/poppins/Poppins-SemiBold.ttf') format('truetype'),
  url('#{$font-path}/poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: 'Poppins';
  src: url('#{$font-path}/poppins/Poppins-Bold.eot');
  src: local('Poppins Bold'), local('Poppins-Bold'),
  url('#{$font-path}/poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/poppins/Poppins-Bold.woff2') format('woff2'),
  url('#{$font-path}/poppins/Poppins-Bold.woff') format('woff'),
  url('#{$font-path}/poppins/Poppins-Bold.ttf') format('truetype'),
  url('#{$font-path}/poppins/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}
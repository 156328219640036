.popmake-content {
  text-align: center !important;
  
  img {
    margin: calc(-18px - 3rem) calc(-18px - 0.5rem) 0;
    width: calc(100% + 36px + 1rem);
    max-width: 150% !important;
    @media (min-width: 768px) {
      margin: calc(-18px - 3rem) calc(-18px - 1rem) 0;
      width: calc(100% + 36px + 2rem);
    }
    @media (min-width: 992px) {
      margin: calc(-18px - 3rem) calc(-18px - 2rem) 0;
      width: calc(100% + 36px + 4rem);
    }
    @media (min-width: 1200px) {
      margin: calc(-18px - 3rem) calc(-18px - 3rem) 0;
      width: calc(100% + 36px + 6rem);
    }
  }
  
  h1 {
    font-size: 2rem;
    color: $primary;
    font-weight: 700;
    margin-top: 3rem;
    @media (min-width: 576px) {
      font-size: 2.5rem;
    }
    @media (min-width: 768px) {
      font-size: 3rem;
    }
    @media (min-width: 992px) {
      font-size: 3.1rem;
    }
  }
  
  h3 {
    font-size: 1.5rem;
    color: #283542;
    @media (min-width: 576px) {
      font-size: 1.6rem;
    }
    @media (min-width: 768px) {
      font-size: 1.7rem;
    }
    @media (min-width: 992px) {
      font-size: 1.8rem;
    }
  }
  
  p {
    font-size: 1rem;
    line-height: 2;
    font-weight: 300;
    color: $dark-grey;
    margin-top: 3rem;
    padding: 0 0.5rem;
    @media (min-width: 576px) {
    }
    @media (min-width: 768px) {
      padding: 0 1rem;
    }
    @media (min-width: 992px) {
      padding: 0 2rem;
    }
    @media (min-width: 1200px) {
      padding: 0 3rem;
    }
  }
  
  .btn-success {
    font-size: 0.6rem;
    padding: 0.5rem 2.4rem;
    margin-bottom: 3rem;
    @media (min-width: 576px) {
      font-size: 0.75rem;
      padding: 0.75rem 3.5rem;
    }
    @media (min-width: 768px) {
      font-size: 0.9rem;
      padding: 0.85rem 4.2rem;
    }
  }
}
.home-service {
  padding: 0 0 1.75rem;
  @media (min-width: 768px) {
    padding: 5.75rem 0;
  }
  
  p {
    color: $dark-grey;
    font-weight: 600;
  }
  
  .services-listing {
    position: relative;
    padding-top: 1.5rem;
  }
  
  .bottom-image-service {
    height: auto;
    max-height: 200px;
    width: auto;
    margin: 0 auto -60px auto;
    @media (min-width: 576px) {
      max-height: 225px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    @media (min-width: 768px) {
      max-height: 260px;
    }
  }
  
  .home-service-main {
    text-align: center;
    transition: all 1s ease;
    padding-bottom: 15px;
    padding-top: 15px;
    @media (min-width: 768px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    
    &:hover {
      box-shadow: none;
    }
    
    .service-images {
      width: auto;
      height: 90px;
      transition: all 1s ease;
    }
    
    h3, h4, h5 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-top: 1.5rem;
      margin-bottom: 0.75rem;
    }
    
    .services-description {
      color: $dark-grey;
      line-height: 1.7rem;
    }
    
    a {
      color: $black;
      text-decoration: none;
    }
    
    a:hover {
      color: $black;
      
      h3 {
        color: #63055b;
      }
      
      img {
        filter: grayscale(100%);
      }
    }
  }
  
  
  .card-title {
    font-size: 1.5rem;
    font-weight: 700;
    @media (min-width: 768px) {
      font-size: 2rem;
      
    }
  }
  
  .card-title-sub {
    font-size: 1.25rem;
    font-weight: 600;
    color: $metro-subtitle;
    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
    @media(min-width: 1200px) {
      font-size: 1.75rem;
    }
  }
  
  .services-tabs {
    .nav-tabs {
      border: 0 !important;
      
      .nav-link {
        border: 0 !important;
      }
    }
    
    a {
      width: 30%;
      text-align: center;
      line-height: 1;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin-top: 1.5rem;
      border-radius: 1rem 1rem 0 0;
      color: $white !important;
      font-weight: 600;
      font-size: 0.75rem;
      @media(min-width: 576px) {
        font-size: 1rem;
      }
      @media (min-width: 992px) {
        font-size: 1.2rem;
      }
      
      &:nth-child(2) {
        width: 40%;
      }
    }
    
    .nav-tabs .active {
      margin-top: 0;
      
      &:after {
        margin-top: 1rem;
        margin-bottom: -1rem;
        content: "\f078";
        display: block;
        font-family: "Font Awesome 5 Pro";
      }
    }
    
    #nav-elect-tab {
      background: linear-gradient(to right, #f12711 0%, #f5af19 100%);
    }
    
    #nav-plumb-tab {
      background: linear-gradient(to right, #61045f 0%, #aa066b 100%);
    }
    
    #nav-aircon-tab {
      background: linear-gradient(to right, #287db6 0%, #643877 100%);
    }
  }
  
  .tab-content {
    .tab-pane {
      margin-top: 4rem;
    }
    
    .row {
      margin-top: 1rem;
    }
  }
  
  .location-services-icons {
    h5 {
      margin-top: 1rem;
      color: #2e3192;
    }
  }
  
  .btn-more {
    i {
      font-size: 1.2rem;
      color: white;
      vertical-align: inherit;
      margin-top: -3px;
      margin-right: 0.5rem;
    }
    
    span {
      font-size: 0.8rem;
      font-weight: 300;
      color: white;
      padding-left: 1rem;
    }
    
    &:hover {
      span {
        color: $white;
      }
    }
    
  }
  
  .disabled {
    &:hover {
      cursor: default;
    }
  }
  
}

.location-services-icons {
  padding-top: 4rem;
  padding-bottom: 0;
  @media (min-width: 992px) {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
}

.entry-title {
  color: $secondary;
}

.entry-content {
  ul {
    list-style-type: none;
    line-height: 2rem;
    padding: 0 2rem 0 0;
    
    //padding-left: 1.8rem;
    
    li {
      position: relative;
      margin-left: 2rem;
      
      &:before {
        content: "";
        background-image: url("#{$img-path}/green-tick.png");
        background-repeat: no-repeat;
        background-position: center;
        min-width: 19px;
        height: 16px;
        position: absolute;
        top: 0.25rem;
        left: -2rem;
      }
      
      a {
        color: #858585;
        
        &:hover {
          color: #009394;
        }
      }
    }
    
  }
}

.container-left-half {
  //these padding and margin are to reflect original bootstrap container
  //padding-right: 15px;
  //padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @media (min-width: 576px) {
    //max-width for container at this width is 540px
    max-width: calc(540px - 30px);
  }
  @media (min-width: 768px) {
    margin-right: 0; //so the box aligns left;
    //max-width for container at this width is 720px
    max-width: calc(360px - 30px);
  }
  @media (min-width: 992px) {
    //max-width for container at this width is 960px
    max-width: calc(480px - 30px);
  }
  @media (min-width: 1200px) {
    //max-width for container at this width is 1140px
    max-width: calc(570px - 30px);
  }
}

// location icon

.fa-map-marker-alt {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 100% 0% 55% 50% / 55% 0% 100% 50%;
  background: $metro-primary;
  transform: rotate(135deg);
  position: relative;
  margin-top: 0.5rem;
  &:after{
    content: "";
    width: 35%;
    height: 35%;
    background-color: $metro-text-dark;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 30%;
    border-radius: 50%;
  }
}
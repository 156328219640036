//scss file for our MENU

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(46, 49, 146, 1)' stroke-width='3' stroke-linecap='0' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  width: 2em !important;
  height: 3em !important;
  
}
.navbar-toggler{
  outline: none;
}
.navbar-m-dark {
  background: #e8e8e8 !important;
}

.navbar {
  padding: 0.75rem 0;
  background: $white;
  box-shadow: 0 .25rem .5rem 0 rgba(0,0,0,.1);
  
  a {
    @media(min-width: 430px) {
      font-size: 0.75rem;
    }
    @media(min-width: 1200px) {
      font-size: 0.875rem;
    }
    
  }
  
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .custom-logo-link {
    
    img {
      height: auto;
      width: 180px;
      @media(min-width: 430px) {
        width: 200px;
      }
    }
  }
  
  .nav-link {
    color: $metro-menu-color !important;
    @media (min-width: 992px) {
      padding: 0 1.5rem !important;
      //letter-spacing: 0.05rem;
    }
    
    &:hover {
      color: $metro-primary !important;
    }
    
    &:last-child {
      //padding-right: 0 !important;
    }
  }
  
  .product-review {
    width: 30%;
    padding: 0 2rem;
  }
}

.navbar-nav {
  //text-align: center;
  
  .dropdown-menu {
    //text-align: left;
    position: static;
    float: none;
    background: $white;
    border: none;
    margin: 0;
    border-radius: 0;
    //padding: 2.2rem 0.5rem 1rem;
    @media(min-width: 992px) {
      padding: 2.2rem 0.5rem 1rem;
      box-shadow: 0 0.5rem .25rem -0.25rem rgba(0,0,0,.1);
    }
  }
  
  li {
    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }
}

.navbar-nav a {
  font-weight: 600;
}

.dropdown-item {
  color: $black;
  padding: .25rem 1rem;
  //text-align: center;
  
  &:focus,
  &:hover,
  &:active {
    color: $green;
    text-decoration: none;
    background-color: $white;
    transition: ease-in 0.3s;
  }
}

.navbar-border {
  /*height: 0.625rem;*/
  height: auto;
  background-color: $black;
  color: $white;
  //text-align: center;
  font-size: 0.8rem;
  //padding-top: 0.1rem;
  //padding-bottom: 0.5rem;
  padding: 0.5rem;
  padding-bottom: 0.7rem;
  
  span {
    a {
      color: #01aeff;
    }
  }
}

.dropdown-toggle:after {
  content: none;
}

.nav-bookcall {
  .nav-phone {
    color: #2a3548;
    font-size: 0.875rem;
    font-weight: 700;
    margin-right: 0;
    line-height: 0;
    vertical-align: middle;
    
    @media (min-width: 420px) {
      font-size: 1.125rem;
    }
    
    @media(min-width: 768px) {
      font-size: 1.25rem;
    }
    @media(min-width: 1200px) {
      line-height: 2.7;
      font-size: 1.5rem;
      margin-right: 1rem;
      font-weight: bolder;
    }
  }
  .btn-primary {
    font-size: 1rem;
    @media(min-width: 1200px) {
      padding: .75rem 2.75rem;
    }
  }
}

.dropdown-menu {
  //text-align: center;
}

@media (min-width: 992px) {
  #mobile-menu {
    display: none;
  }
}

#main-menu {
  display: none;
}

@media (min-width: 992px) {
  #main-menu {
    display: inline-flex;
  }
}

.dropdown-item, .custom-dropdown-item {
  font-weight: 600 !important;
  @media (min-width: 576px) {
    color: #323232;
    padding: .25rem 1rem;
    //text-align: center;
  }
  @media (min-width: 992px) {
    color: #636363;
    text-align: left;
  }

  &:hover {
    color: $metro-primary;
    text-decoration: none;
  }
}

.findplumb-menu {
  margin: 0 -8px -16px;
  padding: .5rem;
  @media (max-width: 576px) {
    margin: 10px 0 0 -.25rem;
    padding: .625rem 0 0;
  }

  a .fal {
    margin-right: 4px;
    color: $metro-primary
  }

  a:hover .fal {
    color: $metro-primary;
    transition: ease-in .3s
  }

  a:active, a:focus, a:hover {
    color: $metro-primary;
    background: 0 0;
  }
}

#main-menu {
  //3 level dropdowns
  .dropdown-menu {
    .dropdown-menu {
      left: 100%;
      top: -0.5rem;
      padding: 0.5rem 0;
      display: none !important;
    }

    .dropdown > .custom-dropdown-item:after {
      content: "";
      width: 0;
      height: 0;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      border-top: 5px solid $metro-menu-color;
      float: right;
      margin-top: 10px;
      transition: transform 0.25s;
    }
  }

  .dropdown:hover {
    > .dropdown-menu {
      display: block !important;
    }

    > .custom-dropdown-item:after {
      border-top: 5px solid $metro-primary;
      transform: rotate(-90deg);
    }
  }
}
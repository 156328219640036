.blog-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
  
  img {
    border-radius: 0.5rem;
    //opacity: 0.7;
  }
  
  .card-img-overlay {
    bottom: 1rem;
  }
  
  
  .card {
    margin: 2rem 0;
    border-radius: 1rem;
    box-shadow: 0 0 15px -5px $dark-grey;
    border: none;
    height: 33rem;
    
    .card-img-top {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      position: relative;
    }
    
    .attachment-blogSmall {
      border-radius: 1rem 1rem 0 0;
    }
  }
  
  .blog-date {
    position: absolute;
    padding: 0.25rem 0.5rem;
    background-color: $primary;
    color: $white;
    font-weight: 600;
    border-radius: 0.33rem;
    top: 20px;
    right: 15px;
  }
  
  .entry-header {
    border-bottom: 1px solid $light-grey;
    padding-bottom: 1rem;
    color: $black;
    
    .entry-title {
      a {
        color: $dark-black;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
    
    .blog-categories {
      a {
      }
    }
  }
  
  .entry-content {
    padding-top: 0.75rem;
    
    p {
      margin: 0;
      color: $dark-grey;
      font-size: 1.12rem;
      line-height: 1.6;
    }
    
    .blog-readmore {
      font-weight: 600;
      margin: 2rem 0 1rem;
      display: inline-block;
      
      i {
        font-size: 0.75rem;
      }
    }
  }
}

// blog single page css

.blog-right {
  padding-bottom: 2rem;
  
  img {
    border-radius: 15px;
    width: 100%;
  }
  
  .title {
    color: $metro-title !important;
    font-size: 2.5rem !important;
    font-weight: 700 !important;
    margin: 2rem 0 1.3rem 0 !important;
  }
  
  h2, h3, strong {
    color: $metro-title !important;
    //font-size: 2rem !important;
    font-weight: 700 !important;
    //margin: 2rem 0 1.3rem 0 !important;
  }
  
  h2, h3 {
    font-size: 2rem !important;
    margin: 2rem 0 1.3rem 0 !important;
  }
  
  .description {
    color: #858585;
    
    h4, h5 {
      color: $black;
    }
    
    ul {
      list-style-type: none;
      line-height: 2rem;
      padding: 0;
      //padding-left: 1.8rem;
      
      li {
        
        display: flex;
        position: relative;
        
        &:before {
          content: "";
          background-color: transparent;
          
          background-image: url("#{$img-path}/green-tick.png");
          display: table;
          background-repeat: no-repeat;
          background-position: center;
          vertical-align: middle;
          min-width: 19px;
          height: 16px;
          margin-right: 0.8rem;
          margin-top: 0.3rem;
          //margin-right: 0.35rem;
          //margin-left: -0.3rem;
          
        }
        
        a {
          color: #858585;
          
          &:hover {
            color: #009394;
          }
        }
      }
      
    }
  }
}

.social-blog {
  h6 {
    padding-bottom: 1rem;
  }
  
  .fab {
    padding-right: 0.5rem;
  }
  
  img {
    display: inline;
    height: 15px;
    width: auto;
    margin: 0 5px;
    border-radius: 0;
  }
}


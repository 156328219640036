.zip-section {
 
  @media(min-width: 576px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  @media(min-width: 992px) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  @media(max-width: 768px) {
    .container {
      padding: 0;
      
      .row {
        margin-left: 0;
        margin-right: 0;
        
        .col-12 {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  
  .first-col {
    @media (min-width: 992px) {
      padding-right: 0;
    }
    
    img {
      width: 100%;
      height: auto;
      border-radius: 0;
      @media(min-width: 576px) {
        border-radius: 0 0 0.5rem 0.5rem;
      }
      @media(min-width: 992px) {
        border-radius: 0.5rem 0 0 0.5rem;
        height: 100%;
      }
    }
  }
  
  .second-col {
    @media(min-width: 992px) {
      padding-left: 0;
    }
    
    .zip-right {
      padding: 1.5rem 0.75rem;
      background-color: #21285e;
      border-radius: 0;
      @media(min-width: 576px) {
        border-radius: 0.5rem 0.5rem 0 0;
      }
      @media (min-width: 992px) {
        padding: 3.5rem 2rem;
        border-radius: 0 0.5rem 0.5rem 0;
      }
    }
    
    img {
      max-width: 50%;
      @media(min-width: 992px) {
        max-width: 75%;
      }
    }
    
    h4 {
      padding-top: 1.5rem;
      padding-bottom: 1rem;
      font-size: 1.8rem;
      font-weight: 700;
      @media(min-width: 992px) {
        padding-bottom: 0.5rem
      }
      @media(min-width: 1200px) {
        font-size: 2.15rem;
        padding-bottom: 1.25rem;
      }
    }
    
    p {
      font-size: 1rem;
      margin: 0;
      line-height: 1.2;
      @media (min-width: 1200px) {
        font-size: 1.33rem;
      }
    }
  }
}
#accordionModule {
  margin-bottom: 7rem;
  
  .card {
    border: none;
    margin: 3rem 0;
    
    .card-header {
      border: none;
      //background-color: #feeee6;
      background-color: $light-grey;
      border-radius: 5px;
    }
    
    .card-body {
      padding: 1rem 3rem 0;
      color: $dark-grey;
      
      p {
        margin: 0;
      }
    }
  }
  
  .btn-link {
    font-size: 1rem;
    font-weight: 600;
    font-style: italic;
    color: $metro-learn-more;
    cursor: pointer;
    
    &:before {
      /*font-family: "Font Awesome 5 Pro";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;*/
      margin-right: 1rem;
      content: " ";
      background-image: url("#{$img-path}/arrow-left.png");
      background-size: contain;
      height: 15px;
      width: 15px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.5s ease;
    }
  }
  
  .btn-link[aria-expanded="true"] {
    &:before {
      //content: "\f068";
      transform: rotate(90deg);
    }
  }
  
  .btn-link[aria-expanded="false"] {
    &:before {
      //content: "\f067";
      transform: rotate(-90deg);
    }
  }
}

.suburb-char {
  margin: 2rem 0 .5rem .25rem;
  font-weight: 700;
}


.suburb-name {
  color: #7a8185;
  margin: .15rem .25rem;
  padding: 0;
  display: inline-block;
}
#accordionModule .card .card-body p{
  margin: 0 0 1rem 0;
  font-size: 1rem;
  line-height: 1.9;
}
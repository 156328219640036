//this is styling for hero banner in other pages

.other-hero {
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  @media(max-width:575px) {
    background-image: linear-gradient(to bottom, #672374, #332f84) !important;
  }

  .no-gutters {
    padding: 2rem 2rem 0.5rem 2rem;
    @media (min-width: 969px) {
      padding: 4rem 0;
    }

    .other-right {
      padding: 1rem 0;
      text-align: center;
      @media (min-width: 996px) {
        padding: 4rem 3rem;
        text-align: left;
      }

      .h1-first {
        color: #fff;
        font-size: 2rem;
        font-weight: bold;
        @media (min-width: 575px) {
          font-weight: 600;
          font-size: 2rem;
        }
        @media (min-width: 767px) {
          font-weight: 600;
          font-size: 2rem;
        }
        @media (min-width: 996px) {
          font-weight: bold;
          font-size: 2.5rem;
        }
      }

      .h1-second {
        display: block !important;
        font-size: 3rem;
        color: #fff;
        font-weight: bold;
        @media (min-width: 996px) {
          font-size: 4.5rem;
        }
      }
    }
  }
}

.second-other-part {
  background-color: $metro-second-other;
  padding: 1.5rem 0 2rem 0;
  @media (min-width: 996px) {
    padding: 1.5rem 0;
  }

  h4 {
    display: inline;
    color: white;
    font-style: italic;
    font-size: 2rem;
    text-shadow: 2px 2px #000000;
    vertical-align: middle;
    //padding-bottom: 3rem;
    line-height: 1.5;
    @media (min-width: 996px) {
      padding-bottom: 0;
      font-size: 2.6rem;
    }

    span {
      color: $metro-primary;
      font-weight: bold;
    }
  }
  
  .book-now-text {
    display: inline;
    color: white;
    font-style: italic;
    font-size: 2rem;
    text-shadow: 2px 2px #000000;
    vertical-align: middle;
    //padding-bottom: 3rem;
    line-height: 1.5;
    @media (min-width: 996px) {
      padding-bottom: 0;
      font-size: 2.6rem;
    }
    
    span {
      color: $metro-primary;
      font-weight: bold;
    }
  }
  

  .other-btn {
    .btn {
      padding: 0.8rem 5rem;
      border-radius: 10px;
      border: 8px solid #1d2e4c;
      margin-top: 1.5rem;
      font-size: 18px;
      @media (min-width: 1200px) {
        padding: 0.5rem 4rem;
        margin-top: 0;
      }

      &:hover, &:focus, &:active {
        background-color: $dark-green !important;
        box-shadow: none !important;
        border: 8px solid #1d2e4c !important;
      }
    }
  }

}
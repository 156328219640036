.accordion-div {
  h4 {
    color: $black;
    font-size: 2.125rem;
    font-weight: 700;
    margin: 2rem 0 1.3rem 0;
  }
  
  #accordionModule {
    margin-bottom: 0;
    
    .card {
      .card-body {
        padding: 1rem 0.5rem 0;
      }
    }
  }
}
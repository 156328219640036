.near-me-hero {
	height:              auto;
	background-repeat:   no-repeat;
	background-size:     cover;
	background-position: center;
	position:            relative;
	color:               #FFF;
}

// near me css
.near-me {
	padding:       5rem;
	margin-bottom: 3rem;
	
	.location-title {
		font-size:   2rem;
		font-weight: 400 !important;
		color:       $white;
		@media (min-width: 768px) {
			font-size:   1.5rem;
			font-weight: unset !important;
		}
		@media (min-width: 992px) {
			font-size: 2rem;
			
		}
	}
	
	.location-sub-title {
		font-weight: 700 !important;
		color:       $white;
		font-size:   2rem;
		@media (min-width: 768px) {
			font-size: 2.5rem;
		}
		@media (min-width: 992px) {
			font-size: 4rem;
		}
		
		
	}
	
	.location-text-desc {
		font-size: 1rem;
		color:     $white;
	}
	
	.location-shortcode {
		display: ruby;
		
		.wpdreams_asl_container {
			padding-top:    10px;
			padding-bottom: 10px;
		}
		
		.asl_w * {
			padding-right: 10px;
		}
		
		#ajaxsearchlite1 {
			.probox {
				.proinput {
					input {
						font-size: 15px;
					}
				}
			}
		}
	}
	
	.location-text-desc {
		font-size: 0.8rem;
		@media (min-width: 768px) {
			padding-top: 0.5rem;
			font-size:   1rem;
		}
		@media (min-width: 992px) {
			padding-top: 1rem;
			font-size:   1rem;
		}
	}
}
.above-points {
  background-position: center;
  background-repeat: no-repeat;
  color: $black;
  text-align: center;
  background-size: cover;
  padding: 30px 0 0;
  position: relative;
  z-index: 2;
  
  @media(min-width: 768px) {
    padding: 45px;
  }
  @media(min-width: 992px) {
    padding: 30px 0 0;
    background-size: cover;
  }
  
  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    @media(min-width: 992px) {
      font-size: 2.25rem;
    }
  }
  
  p {
    font-size: 1rem;
    margin: 20px 0 0;
    color: $dark-grey;
    font-weight: 600;
  }
  
  .mb-3 {
    font-size: 1.25rem;
  }
  
  .review-image {
    
    @media(min-width: 768px) {
      padding-top: 0;
    }
    @media(min-width: 1000px) {
      padding-top: 0;
    }
    @media(min-width: 1200px) {
      padding-top: 0;
    }
  }
  
  .col-md-8 {
    padding-top: 2rem;
    @media(min-width: 768px) {
      padding-top: 3rem;
    }
  }
  
  .emp-img {
    left: 0;
    z-index: 1;
    bottom: -75px;
    @media(min-width: 992px) {
      bottom: -60px;
    }
    @media(min-width: 1600px) {
      bottom: -65px;
    }
    @media(min-width: 2000px) {
      bottom: -75px;
    }
  }
}

.semi-circle-wrapper {
  height: 100px;
  width: 100%;
  overflow: hidden;
  background-color: #e8e8e8;
  position: relative;
  margin-top: -4rem;
  @media(min-width: 992px) {
    margin-top: -2rem;
  }
  
  .semi-circle {
    position: absolute;
    background-color: #fff;
    height: 500vw;
    width: 500vw;
    border-radius: 50%;
    bottom: 0;
    left: -200vw;
    z-index: 1;
    @media(min-width: 2000px) {
      height: 1000vw;
      width: 1000vw;
      left: -450vw;
    }
  }
  
}

.box-below {
  background-color: #e8e8e8;
  //background-image: linear-gradient(#e8e8e8 0%, #e8e8e8 25%, #ffffff 75%);
  min-height: 1rem;
  height: auto;
  width: 100%;
}

.page-mode-selling {
  padding-bottom: 2rem;
  @media(min-width: 992px) {
    padding-bottom: 4rem;
  }
}

.home-points {
  background: linear-gradient(#e8e8e8 0, #e8e8e8 65%, #fff 90%);
  padding-top: 6rem;
  @media(min-width: 992px) {
    background: linear-gradient(#e8e8e8 0, #e8e8e8 25%, #fff 60%);
  }
  @media(max-width: 992px) {
    margin-bottom: -6rem;
  }
  @media(max-width: 768px) {
    margin-bottom: -2rem;
  }
  
  .points-wrapper {
    background-color: white;
    position: relative;
    text-align: center;
    padding: 2rem;
    margin-bottom: 6rem;
    border: 0.15rem solid #cdcdcd;
    
    @media(min-width: 992px) {
      padding: 1rem;
      margin-bottom: 0;
      border: unset;
    }
    @media(min-width: 1200px) {
      padding: 2rem;
    }
    
    h3 {
      font-size: 1.5rem;
      @media(min-width: 1200px) {
        padding: 0 1.25rem;
      }
    }
    
    img {
      margin-top: -5rem;
      padding-bottom: 1rem;
    }
    
    .btn {
      border-radius: 0.3rem;
    }
  }
}


